.pagination {
    margin: 1em 0;
}
.pagination span {
    display: inline-flex;
    justify-content: center;
    cursor: pointer;   
    border: 1px solid transparent;
    user-select: none;
    padding: 0 5px;
    height: 2em;
    min-width: 2em;
    align-items: center;
    vertical-align: middle;
}
.pagination .selected { 
    color:blue;
    border-color: #333;
}