html,
body {
  scrollbar-gutter: stable;
}

body {
    background: var(--bg);
    font-size: 16px;
    font-family: Montserrat, Roboto, sans-serif;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-weight: 500;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
} */

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

input,
button,
select {
  border: none;
  background: none;
  outline: none;
  font-size: 1em;
  font-family: Montserrat, Roboto, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main { flex: 1 0 auto; }
.footer { 
    flex: 0 0 auto; 
    margin-top: 20px; 
    background-color: #f5f7fa; 
    font-size: 14px;
    color: rgba(0,26,52,.6);
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.icon {
  width: 12px;
  height: 12px;
}

/* .container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
} */
.container {
    width: 100%;
    max-width: 1366px;
    padding: 0 20px;
    margin: 0 auto;
    box-sizing: border-box;
}

button {
    color: #fff;
    background: var(--violet-dark);
    border-radius: 6px;
    padding: 9px 20px;
    font-weight: normal;
    line-height: 20px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
button:disabled {
    cursor: not-allowed;
    background-color: rgba(0,48,120,.04);
    color: #96a3ae;
    pointer-events: none;
}
button:hover {
  background: var(--violet);
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  /* -webkit-appearance: none; */
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    /* Firefox */
    /* -moz-appearance: textfield;  */
}

input {
  padding: 0;
}

.preloader {
  flex-grow: 1;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:after {
    content: '';
    display: table;
    clear: both;
  }
  .row {
    margin: 0 -0.75rem;
    /*padding-bottom: 2.5rem;*/
  }
  .row .col {
    padding: 0 0.75rem;
  }
  .col {
    display: inline-block; /*float: left;*/
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 1.5rem;
  }
  .s1 {
    width: 8.3333333333%;
  }
  .s2 {
    width: 16.6666666667%;
  }
  .s3 {
    width: 25%;
  }
  .s4 {
    width: 33.3333333333%;
  }
  .s5 {
    width: 41.6666666667%;
  }
  .s6 {
    width: 50%;
  }
  .s7 {
    width: 58.3333333333%;
  }
  .s8 {
    width: 66.6666666667%;
  }
  .s9 {
    width: 75%;
  }
  .s10 {
    width: 83.3333333333%;
  }
  .s11 {
    width: 91.6666666667%;
  }
  .s12 {
    width: 100%;
  }
  @media only screen and (min-width: 601px) {
    .m1 {
      width: 8.3333333333%;
    }
    .m2 {
      width: 16.6666666667%;
    }
    .m3 {
      width: 25%;
    }
    .m4 {
      width: 33.3333333333%;
    }
    .m5 {
      width: 41.6666666667%;
    }
    .m6 {
      width: 50%;
    }
    .m7 {
      width: 58.3333333333%;
    }
    .m8 {
      width: 66.6666666667%;
    }
    .m9 {
      width: 75%;
    }
    .m10 {
      width: 83.3333333333%;
    }
    .m11 {
      width: 91.6666666667%;
    }
    .m12 {
      width: 100%;
    }
  }
  @media only screen and (min-width: 993px) {
    .l1 {
      width: 8.3333333333%;
    }
    .l2 {
      width: 16.6666666667%;
    }
    .l3 {
      width: 25%;
    }
    .l4 {
      width: 33.3333333333%;
    }
    .l5 {
      width: 41.6666666667%;
    }
    .l6 {
      width: 50%;
    }
    .l7 {
      width: 58.3333333333%;
    }
    .l8 {
      width: 66.6666666667%;
    }
    .l9 {
      width: 75%;
    }
    .l10 {
      width: 83.3333333333%;
    }
    .l11 {
      width: 91.6666666667%;
    }
    .l12 {
      width: 100%;
    }
    .l20 {
        width: 20%;
    }
  }
  
  .flex {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .flex.middle {
    -webkit-box-align: center;
    -moz-box-align: center;
    -o-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .flex.wrap {
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
.flex.full-line {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.flex-separator {
    flex: 1;
}
@media only screen and (max-width:600px) { .hide-on-small-only, .hide-on-small-and-down { display:none !important; }
}@media only screen and (max-width:992px) { .hide-on-med-and-down { display:none !important; }
}@media only screen and (min-width:601px) { .hide-on-med-and-up { display:none !important; }
}@media only screen and (min-width:600px) and (max-width:992px) { .hide-on-med-only { display:none !important; }
}@media only screen and (min-width:993px) { .hide-on-large-only { display:none !important; }
}@media only screen and (min-width:1201px) { .hide-on-extra-large-only { display:none !important; }
}@media only screen and (min-width:1201px) { .show-on-extra-large { display:block !important; }
}@media only screen and (min-width:993px) { .show-on-large { display:block !important; }
}@media only screen and (min-width:600px) and (max-width:992px) { .show-on-medium { display:block !important; }
}@media only screen and (max-width:600px) { .show-on-small { display:block !important; }
}@media only screen and (min-width:601px) { .show-on-medium-and-up { display:block !important; }
}@media only screen and (max-width:992px) { .show-on-medium-and-down { display:block !important; }
}@media only screen and (max-width:600px) { .center-on-small-only { text-align:center; justify-content: center; }
}

.swiper .swiper-slide { height: auto; }

.modal_close {
    font-size: 26px;
    height: 26px;
    width: 26px;
    color: #afafaf;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}
