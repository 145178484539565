.sidebar_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px 20px;
}
.sidebar_content {
    overflow-y: auto;
    scrollbar-color: #d3d4dd transparent;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: -10px;
    margin-left: -20px;
    padding-right: 10px;
    padding-left: 20px;
}
.sidebar_content::-webkit-scrollbar {
    width: 6px
}
.sidebar_content::-webkit-scrollbar-track {
    background-color: transparent
}
.sidebar_content::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #d3d4dd;
    border-radius: 3px
}
.sidebar_content::-webkit-scrollbar-thumb:hover {
    background: #605f65
}
.sidebar_header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}
.sidebar_header h3 {
    margin: 0;
}
.sidebar_header .close {
    cursor: pointer;
    font-size: 1.17em;
}
.sidebar_action {
    font-size: 0.875em;
    padding: 12px 20px 24px;
    margin: 0 -20px -24px;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    display: flex;
    gap: 8px;
}
.sidebar_action button {
    flex: 1;
}
.button {
    font-size: 0.875em;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    height: 2em;
    padding: 0 5px;
    position: relative;
}
.count {
    position: absolute;
    width: 1.8em;
    height: 1.8em;
    background: var(--violet);
    right: -1em;
    top: -1em;
    border-radius: 50%;
    font-weight: 700;
    font-size: 10px;
    color: var(--light);
    text-align: center;
    line-height: 1.8em;
}