.inline_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.inline_wrap a {
    font-size: 14px;
    padding: 10px;
    background-color: whitesmoke;
    border-radius: 6px;
}
@media only screen and (max-width: 600px) {
    .inline_wrap { 
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-left:-20px;
        margin-right:-20px;
        padding: 0 20px;
    }
    .inline_wrap a {
        display: inline-flex;
        align-items: center;
        justify-content: center;  
    }
    .inline_wrap a span {
        display: block;
        text-align: center;
        min-width: 120px; 
    }
}
.grid_wrap {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}
.grid_wrap .col {
    padding: 0 0.5rem;
    margin-bottom: 1rem;
}
.grid_wrap a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding: 10px;
    background-color: whitesmoke;
    height: 100%;
    border-radius: 6px;
}
.grid_wrap a svg {
    font-size: 1.125rem;
    min-width: 1em;
}
.grid_wrap a span { flex: 1; }