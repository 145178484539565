.header[data-count] {
    display: flex;
}
.header[data-count]::after {
    font-size: 14px;
    content: attr(data-count);
    display: block;
    font-weight: 700;
    color: #7c7c7c;
}
.controls_wrap {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 1em;
    margin-bottom: 1em;
}
.controls_wrap aside {
    padding: 5px;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    border-radius: 8px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 14px;
}
.filter {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
}
.filter_name {
    width: 100%;
    font-weight: 600;
}
.filter_group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.filter_inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 10px;
    background-color: #e7e7e7;
    border-radius: 4px;
    width: 100%;
}
.filter_inputs input {
    width: calc(50% - .5rem);
}

.filter input[type="number"] {
    flex: 1;
}
.filter input[type="range"] {
    width: 100%;
}
.color {
    width: 1em;
    height: 1em;
    display: inline-block;
    border-radius: 1em;
    margin-left: 2px; 
}
