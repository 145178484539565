.top {
    font-size: 12px;
    color: #868686;
    padding-top: .5rem;
}
.top a {
    display: inline-block;
    padding: 4px;
}
.header {
    background: rgb(20, 20, 20);
    color: #fff;
    position: sticky;
    top: 0px;
    z-index: 10;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
}
.header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: relative;
    gap: 1rem;
}
.header nav ul {
    display: flex;
    gap: 1em;
    margin: 0;
    padding: 0;
    list-style: none;
}
.header a { 
    color: inherit;
}
.wrapper_logo {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.catalog_button {
    display: inline-flex;
    align-items: center;
    gap: 6px;
}
.catalog_menu {
    border-radius: 12px;
    position: absolute;
    z-index: 1002;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 1366px;
    background: #fff;
    height: max-content;
    display: flex;
    box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 4px 4px rgba(0,0,0,.04), 0 20px 40px rgba(0,0,0,.08);
}
.catalog_menu_rootmenu {
    position: relative;
    height: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 24px 0 24px 0;
}
.catalog_submenu {
    padding: 24px 24px 32px 20px;
    max-height: 600px;
    background: #fff;
    color: #000;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    -moz-border-radius-bottomright: 12px;
    -moz-border-radius-topright: 12px;
    overflow-y: visible;
    overflow-x: auto;
    flex: 1;
}

.catalog_menu__root_item {
    height: 36px;
    margin: 0 -1px;
    font-size: 16px;
    color: #000;
    padding: 0 0 0 12px;
    width: 280px;
    border-left: 2px solid rgba(0,0,0,0);
    cursor: pointer;
}
.catalog_menu__root_item:before {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: "";
}
.catalog_submenu__first_level {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 12px;
    display: block;
}
.catalog_submenu_columns {
    columns: 160px 3;
    column-gap: 1rem;
    margin-bottom: 20px;
}
.catalog_submenu_item {
    color: #4e4e4e;
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    position: relative;
    padding-right: 14px;
    word-break: break-word;
    white-space: normal;
    width: 100%;
}
.root_item_active {
    border-left: 2px solid var(--violet);
    color: var(--violet-dark);
}
.overlay {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0px calc(100 * ((1vw + 1vh) - 1vmin));
    z-index: 105;
}
.shopping_cart { font-size: 1.563em; cursor: pointer; position: relative; }
.shopping_cart span {
    position: absolute;
    width: 1.8em;
    height: 1.8em;
    background: var(--violet);
    right: -1em;
    top: -1em;
    border-radius: 50%;
    font-weight: 700;
    font-size: 10px;
    color: var(--light);
    text-align: center;
    line-height: 1.8em;
}