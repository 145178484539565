.search {
    flex: 1;
    max-width: 600px;
}
@media only screen and (max-width: 600px) {
    .search {
        position: absolute;
        width: 100%;
        display: none;
    }
    .search_open {
        display: block; 
    }
}
.mobile_icon_wrap { 
    margin-left: auto;
}
.mobile_icon {
    font-size: 1.563em;
    cursor: pointer;
}
.wrap {   
    position: relative;
    height: 38px;
    z-index: 4; 
}
.form {        
    display: flex;
    align-items: center;
    background: #2b2a2a;    
    border-radius: 6px;
    height: inherit;
    padding: 7px 10px;
    column-gap: 16px;    
    position: relative;    
}
.form button {
    padding: 0;
    background-color: transparent;
}
.input { width: 100%; }
.input input {
    color: inherit;
    outline: none;
    width: 100%;
}
.input input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 45px #fff !important;
}
.input input::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.suggests {
    display: block;
    z-index: 1001;
    background-color: #fff;
    border-top: none;
    border-radius: 0 0 6px 6px;
    color: #333;
    box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 4px 4px rgba(0,0,0,.04), 0 20px 40px rgba(0,0,0,.08);
    position: absolute;
    right: 0;
    left: 0;
}
.suggests_title {
    color: #8c8c8c;
    letter-spacing: .2px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}
.suggests_item {
    display: flex;
    padding: 0 10px 0 1rem;
    white-space: normal;
    line-height: 32px;
    color: #333;
    align-items: center;
    gap: 8px;
    font-size: 0.938em;
}
.suggests_item:last-child {
    padding-bottom: 1rem;
}
.suggests_item svg { 
    color: #8c8c8c;
    font-size: 2rem;
    padding: 0.250em;
    cursor: pointer;
}
.suggests_item_name {
    text-overflow: ellipsis;
    flex: 1 1 0%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,.1);
    z-index: 3;
}