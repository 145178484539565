.form {
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 16px;
}

.group input {
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px 24px;
}

.submit {
  font-weight: 500;
  line-height: 20px;
}