.overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease 0s, transform 0s ease 0.3s;
    transform: translate3d(100%, 0px, 0px);
}
.overlay_open {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
}
.sidebar {
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 1100;
    width: 300px;
    height: 100%;
    /* transition: all 0.3s ease 0s; */
    transform: translate3d(100%, 0px, 0px);
    background: #fff;
    animation-name: slideOutUp;
    animation-duration: .3s;
}
.sidebar_open {   
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 1100;
    width: 320px;
    height: 100%;
    /* transition: all 0.3s ease 0s; */
    background: #fff;
    animation-name: slideInUp;
    animation-duration: .3s;
}

.sidebar_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px 20px;
}
.sidebar_content {
    overflow-y: auto;
    scrollbar-color: #d3d4dd transparent;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: -32px;
    margin-left: -20px;
    padding-right: 26px;
    padding-left: 20px;
}
.sidebar_content::-webkit-scrollbar {
    width: 6px
}
.sidebar_content::-webkit-scrollbar-track {
    background-color: transparent
}
.sidebar_content::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #d3d4dd;
    border-radius: 3px
}
.sidebar_content::-webkit-scrollbar-thumb:hover {
    background: #605f65
}
.sidebar_header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}
.sidebar_header h3 {
    margin: 0;
}
.sidebar_action {
    font-size: 0.875em;
    padding: 12px 20px 24px;
    margin: 0 -20px -24px;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    display: flex;
    gap: 8px;
}
.sidebar_action button {
    flex: 1;
}

@keyframes slideInUp {
    from {
      transform: translate3d(100%, 0, 0);
    }  
    to {
      transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOutUp {
    from {
      transform: translate3d(0, 0, 0);
    }  
    to {
      transform: translate3d(100%, 0, 0);
    }
}
