@keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }
  
  .skeleton {
    --base-color: #f5f5f5;
    --highlight-color: #ffffff;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */
  
    background-color: var(--base-color);
    height: 350px;
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;
  
    position: relative;
    user-select: none;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
  }
  
  .skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    transform: translateX(-100%);
  
    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  @media (prefers-reduced-motion) {
    .skeleton {
      --pseudo-element-display: none; /* Disable animation */
    }
  }
  

.products {
  width: 100%;
  padding: 25px 22px;
}

.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.product {
    background: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
}

.image {
    position: relative;
    display: block;
    width: 100%;
    height: 172px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
}
.image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.gallery {
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
}
.gallery span { 
    flex: 1 1 auto;
    height: 100%;
}
.gallery_pagination {
    align-items: center;
    bottom: -10px;
    display: flex;
    height: 6px;
    justify-content: center;
    left: 0;
    position: absolute;
    width: 100%;
}
.gallery_pagination span {
    background: rgba(0,26,52,.2);
    border-radius: 100%;
    height: 5px;
    margin-left: 2px;
    width: 5px;
}
.gallery_pagination .active {
    background: #6c3eb8;
}

.inbasket,
.inbasket:disabled {
    color: green;
    background-color: rgba(7, 170, 0, 0.102);
}
.wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 172px);
}

.title {
  font-weight: normal;
  font-size: 15px;
  margin: 0;
}

.cat {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey);
  text-transform: uppercase;
  margin-top: 5px;
}

.info {
    margin-bottom: auto;
    padding-bottom: 12px;
    
}

.prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5px;
    padding: 5px 0;
}

.price {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--violet-dark);
}
.compare_price { 
    font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: var(--dark-sea);
}
.percentage { 
    margin-left: 5px;
    color: #f91155; 
}

.purchases {
  color: var(--dark-sea);
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.button {
  margin-top: 24px;
  text-align: center;
}
