.product {
    display: flex;
}

.info {
  display: flex;
  flex-direction: column;
}

.images {
  display: flex;
  gap: 20px;
}

.current {
  width: 375px;
  height: 375px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 6px;
}

.images_list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.images_slider_preveiw {
    display: flex;
    height: 375px;
}
.image {
    width: 90px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid transparent;
}
.image.active {
    border-color: var(--violet-dark);
}
.title {
  font-size: 24px;
}

.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 10px 0 20px 0;
}
.options select {
    height: 2em;
    background-color: #e7e7e7;
    border-radius: 4px;
}

.description {
  font-size: 14px;
  color: #404b55;
}

.stock_critical { color: #e00; }
.stock_low { color: #a80; }
.stock_high { color: #009688; }
.stock_none { color: #aaa; white-space: normal; }

.actions {
  margin-top: 20px;
  display: flex;
  column-gap: 10px;
}

.inbasket,
.inbasket:disabled {
    color: green;
    background-color: rgba(7, 170, 0, 0.102);
}

.add:disabled {
    cursor: not-allowed;
    background-color: rgba(0,48,120,.04);
    color: #96a3ae;
    pointer-events: none;
}

