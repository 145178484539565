.item {  
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    width: 100%;
    padding: 12px 16px;
    display: grid;  
    align-items: center;
    gap: 10px;
    /* grid-template-columns: 20px 100px 2fr 1.5fr 1fr 30px; */
    grid-template-columns: 20px 100px 100px 1fr 1rem;
    grid-template-areas:
        "checkbox img title title remove"
        "checkbox img incrDec price price";
}
.item_disabled { color: #848484; }
.checkbox {
    grid-area: checkbox;
}
.image {
    object-fit: contain;
    width: 100px;
    height: 100px;
    grid-area: img;
}
.title {
    font-size: 14px;
    font-weight: 450;
    margin: 0;
    grid-area: title;
} 
.price {
    font-size: 18px;
    line-height: 35px;
    grid-area: price;
} 
.incrDec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 10px 0; */
    width: 90px;
    grid-area: incrDec;
} 
.incrDec button {
    padding: 0;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeButton {
    color: rgb(241, 131, 131);
    grid-area: remove;
}
.removeButton:hover{
    color: rgb(243, 92, 92);
}
.removeButton:active{
    color: rgb(241, 131, 131);
}

@media(max-width: 1000px){ 
    .title{
        font-size: 13px;
    }  
    .price{
        font-size: 16px;
    }
}
@media(max-width:400px){
    .item{
        flex-direction: column;
    }  
    .title{
        margin-top: 15px;
    }
}

.stock_none { color: #f91155; }
.stock_high { color: #009688; }