.wrap { 
    margin: 0 0 8px 0;
}
@media only screen and (max-width: 600px) {
    .wrap { 
        overflow: auto;
        display: flex;
        white-space: nowrap;
        margin: 0 -20px;
        padding: 0 20px 5px;
    }
}
.picked {
    align-items: center;
    display: inline-flex;
    gap: 5px;
    background: #8b57c6;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    margin: 0 8px 8px 0;
    line-height: 1;
}
.picked:hover {
    background: #a06fd9;
}
.picked_reset {
    background: #f7f7f7;
    color: #333;
}
.picked_reset:hover{
    background: #eee;
}
.picked span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    white-space: nowrap;
}
