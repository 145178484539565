:root {
  --bg: #fff;
  --dark: #191919;
  --dark-sea: #576067;
  --grey: #b8b8b8;
  --white: #fff;
  --violet: #8b57c6;
  --violet-dark: #6c3eb8;
  --light: #f6f6f7;
  --sea: #616e74;
}
