.breadcrumbs {
    font-size: 0.875em;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
a.breadcrumb {
    color: inherit;
    text-decoration: none;
}
a.breadcrumb:hover {
    color: #ff4848;
}
.breadcrumb:not(:first-child)::before {
    display: inline-block;
    margin: 0 0.25rem;
    content: "→";
}
span.breadcrumb { color: #8a8a8a; }