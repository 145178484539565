.basket_header[data-count] {
    display: flex;
}
.basket_header[data-count]::after {
    font-size: 14px;
    content: attr(data-count);
    display: block;
    font-weight: 700;
    color: #7c7c7c;
}
.controls {
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    min-height: 48px;
    padding: 0 16px;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
}
.controls_remove_button {
    font-size: 14px;
    font-weight: 500;
    appearance: none;
    background: transparent;
    border: none;
    color: #f91155;
    height: inherit;
    margin: 0;
    padding: 0;
}
.controls_remove_button:hover {
    background: transparent;
    color: #e00f4e;
}