.wrap { 
    font-size: 0.875em;
    display: flex;
    align-items: center;
    gap: 1em; 
}
.select {
    height: 2em;
    border-radius: 4px;    
}
.button {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    height: 2em;
    padding: 0 5px;
    position: relative;
}
.sidebar_content {
    overflow-y: auto;
    scrollbar-color: #d3d4dd transparent;
    scrollbar-width: thin;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    flex-grow: 1;
    margin-right: -10px;
    margin-left: -20px;
    padding-right: 10px;
    padding-left: 20px;
}
.sidebar_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px 20px;
}
.sidebar_header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
}
.sidebar_header h3 {
    margin: 0;
}
.sidebar_header .close {
    cursor: pointer;
    font-size: 1.17em;
}
.sidebar_action {
    font-size: 0.875em;
    padding: 12px 20px 24px;
    margin: 0 -20px -24px;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    display: flex;
    gap: 8px;
}
.sidebar_action button {
    flex: 1;
}