.modal {
    background-color: white;
    border-radius: 8px;
    border-width: 0;
    padding: 0;
    max-height: 90%;
    max-width: 90%;
    overflow-x: hidden;
} 
.modal[open] {
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.modal[open].modal--closing {
    animation: hide 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}
.modal::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    /* backdrop-filter: blur(1px); */
    animation: none;
}
.modal .modal__container {
    box-sizing: border-box;
    min-height: 50px;
    min-width: 50px;
    padding: 2rem;
}

@keyframes show {
    from {
        opacity: 0;
        transform: translateY(min(100px, 5vh));
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes hide {
    from {
        opacity: 1;
        transform: translateY(0%);
    }
    to {
        opacity: 0;
        transform: translateY(min(100px, 5vh));
    }
}